<template>
  <div class="preview-edit">
    <div class="content">
      <comSearch
        v-if="editCptData.componentName == 'search'"
        :cptData="editCptData"
        :canEdit="true"
      ></comSearch>
      <comCarousel
        v-else-if="editCptData.componentName == 'carousel'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCarousel>
      <comClassify
        v-else-if="editCptData.componentName == 'classify'"
        :cptData="editCptData"
        :canEdit="true"
      ></comClassify>
      <comFootBar
        v-else-if="editCptData.componentName == 'footBar'"
        :cptData="editCptData"
        :canEdit="true"
      ></comFootBar>
      <comHomePageDialog
        v-else-if="editCptData.componentName == 'homePageDialog'"
        :cptData="editCptData"
        :canEdit="true"
      ></comHomePageDialog>
      <comWaterfall_1
        v-else-if="editCptData.componentName == 'waterfall_1'"
        :cptData="editCptData"
        :canEdit="true"
      ></comWaterfall_1>
      <comWaterfall_2
        v-else-if="editCptData.componentName == 'waterfall_2'"
        :cptData="editCptData"
        :canEdit="true"
      ></comWaterfall_2>
      <comWaterfall_3
        v-else-if="editCptData.componentName == 'waterfall_3'"
        :cptData="editCptData"
        :canEdit="true"
      ></comWaterfall_3>
      <comAreaAndClassify
        v-else-if="editCptData.componentName == 'areaAndClassify'"
        :cptData="editCptData"
        :canEdit="true"
      ></comAreaAndClassify>
      <comDiscover
        v-else-if="editCptData.componentName == 'discover'"
        :cptData="editCptData"
        :canEdit="true"
      ></comDiscover>
      <comCube_1
        v-else-if="editCptData.componentName == 'cube_1'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCube_1>
      <comCube_2
        v-else-if="editCptData.componentName == 'cube_2'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCube_2>
      <comCube_3
        v-else-if="editCptData.componentName == 'cube_3'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCube_3>
      <comCube_4
        v-else-if="editCptData.componentName == 'cube_4'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCube_4>
      <comCube_5
        v-else-if="editCptData.componentName == 'cube_5'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCube_5>
      <comCube_6
        v-else-if="editCptData.componentName == 'cube_6'"
        :cptData="editCptData"
        :canEdit="true"
      ></comCube_6>

      <!-- 微页面组件 -->
      <tinyPageTitle
        :cptData="editCptData"
        :canEdit="true"
        @page-info="getPageInfo"
        v-else-if="editCptData.componentName == 'pageTitle'"
      ></tinyPageTitle>
      <tinyPageText
        :cptData="editCptData"
        :canEdit="true"
        v-else-if="editCptData.componentName == 'textContent'"
      ></tinyPageText>
      <tinyPageImage
        :cptData="editCptData"
        :canEdit="true"
        v-else-if="editCptData.componentName == 'imageUrl'"
      ></tinyPageImage>
      <tinyPageProduct
        :cptData="editCptData"
        :canEdit="true"
        v-else-if="editCptData.componentName == 'productListComponent'"
      ></tinyPageProduct>
    </div>
  </div>
</template>

<script>
import comMixin from '@/component/adorn'
import tinyPageTitle from "../__com__/tiny-page/pageTitle.vue"
import tinyPageImage from "../__com__/tiny-page/imageUrl.vue"
import tinyPageProduct from "../__com__/tiny-page/productListComponent.vue"
import tinyPageText from "../__com__/tiny-page/textContent.vue"

export default {
  mixins: [comMixin],
  components: {tinyPageTitle, tinyPageImage, tinyPageProduct, tinyPageText},
  props: {
    editCptData: {
      type: Object,
      default() {
        return {
          componentName: '',
          data: {}
        }
      },
      comment: '当前选定需要编辑的组件数据模型'
    }
  },
  methods: {
    getPageInfo(val) {
      this.$emit('page-info', val)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
